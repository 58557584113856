import React from 'react'
import classNames from 'classnames'
import { PlatformSaveRequest, MapCoordModel } from '../../../../../models/ScenesModels/platform.model'
import { CommentModel } from '../../../../../models/ArtistFormModels/artistForm.model'
import { withTypes, Field } from 'react-final-form'
import LoaderComponent from '../../../../common/Loader/Loader.component'
import arrayMutators from 'final-form-arrays'
import { fieldRequired, composeValidators } from '../../../../../const/validators.const'
import InputComponent from '../../../common/Input/Input.component'
import InputLabelComponent from '../../../ArtistCRM/ArtistForm/InputLabel/InputLabel.component'
import TextAreaComponent from '../../../common/TextArea/TextArea.component'
import { Element, scroller } from 'react-scroll'
import { platformSocial, contactSocial } from './data.const'
import CheckboxComponent from '../../../common/Checkbox/Checkbox.component'
import UploadFileContainer from '../../../../../containers/CRM/common/UploadFile.container'
import { ReactComponent as Doc } from '../../../../../assets/icons/doc.svg'
import { ReactComponent as Remove } from '../../../../../assets/icons/remove.svg'
import { FieldArray } from 'react-final-form-arrays'
import DocumentUploadContainer from '../../../../../containers/CRM/common/DocumentUpload/DocumentUpload.container'
import AddMemberButtonComponent from '../../../ArtistCRM/ArtistForm/AddMemberButton/AddMemberButton.component'
import Button from '../../../../Button/Button.component'
import { PlatformStatus } from '../../../../../models/ScenesModels/platformList.model'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete"
import { GoogleMap, Marker } from '@react-google-maps/api'
import ModerationSidebarContainer from '../../../../../containers/CRM/AdminCRM/PlatformModeration/ModerationSidebar'
import { RequestError } from '../../../../../models/common/error.model'
import ErrorLabelComponent from '../../../common/ErrorLabel/ErrorLabel.component'
import ModalComponent from '../../../../common/Modal/Modal.component'
import { ValidationErrors } from 'final-form'
import shallowequal from 'shallowequal'

import './PlatformForm.style.sass'
import { sidebarTabs } from '../../AdminApproveSidebar/AdminApproveSidebar.component'
import { sceneGenreButtonsData, SceneGenreButtonsData } from '../SceneForm/data.const'
import GenreRadioButtonComponent from '../../../common/GenreRadioButton/GenreRadioButton.component'
import TimePicker from 'rc-time-picker'
import SelectComponent from '../../../common/Select/Select.component'
import moment from 'moment'
import { perfomanceDates } from '../../../../../const/perfomanceDates'


interface PropTypes {
  onSubmit: (values: PlatformSaveRequest, isApply?: boolean) => void;
  isLoading: boolean;
  formInitialValues?: PlatformSaveRequest;
  showValidationErrors: boolean;
  showErrors: () => void;
  onModerate: (values: PlatformSaveRequest) => void;
  onApprove: (values: PlatformSaveRequest) => void;
  onCheckForm: () => void;
  comments?: CommentModel[];
  formStatus: PlatformStatus;
  isFirstEdit: boolean;
  submittionErrors?: RequestError[];
  isSuccessModalVisible: boolean;
  isCheckModalVisible: boolean;
  onPopupApply: (values: PlatformSaveRequest) => void;
  onCheckModalClose: () => void;
  onSuccessModalClose: () => void;
  isApplyLoading: boolean;
  isApproveLoading: boolean;
  isCheckLoading: boolean;
  isDeleteModalVisible: boolean;
  isDeleteLoading: boolean;
  onDeleteModalToggle: () => void;
  onDeleteModalApply: () => void;
}

interface State {
  isHintsVisible: boolean;
  sidebarActiveTabIndex: number;
}

const { Form } = withTypes<PlatformSaveRequest>()
const block = 'platform-form'
 
export default class PlatformFormComponent extends React.Component<PropTypes, State> {
  private formValues?: PlatformSaveRequest;
  private lastSubmitValues?: PlatformSaveRequest;
  private timeOut?: number;
  private setFormValue?: (field: string, value: string | MapCoordModel | null) => void;
  private isMapClicked: boolean;
  
  state = {
    isHintsVisible: true,
    sidebarActiveTabIndex: sidebarTabs['hints'].index
  }

  constructor(props: PropTypes) {
    super(props)

    this.isMapClicked = false
  }

  componentDidMount(): void {
    if (this.props.isFirstEdit) {
      this.timeOut = window.setTimeout(this.autoSaveInterval, 1000 * 30)
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeOut)
  }

  componentDidUpdate(prevProps: PropTypes): void {
    if (!prevProps.comments?.length && this.props.comments?.length) {
      this.setState({
        isHintsVisible: false,
        sidebarActiveTabIndex: sidebarTabs['comments'].index
      })
    }
  }

  scrollToFirstError = (errors: ValidationErrors): void => {
    const errorFields = Object.keys(errors)

    scroller.scrollTo(errorFields[0], {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -230
    })
  }

  autoSaveInterval = (): void => {
    if (!shallowequal(this.formValues, this.lastSubmitValues) && this.formValues) {
      this.props.onSubmit(this.formValues, false)
      this.lastSubmitValues = this.formValues
    }
    
    this.timeOut = window.setTimeout(this.autoSaveInterval, 1000 * 30)
  }

  onModerate = (): void => {
    this.formValues && this.props.onModerate(this.formValues)
  }

  handleAddressSelect = async (
    value: string
  ): Promise<void> => {
    let results: google.maps.GeocoderResult[]
    let latLng: google.maps.LatLngLiteral
  
    try {
      results = await geocodeByAddress(value)
    } catch {
      !this.isMapClicked && this.clearCoordinates()
      return
    }

    try {
      latLng = await getLatLng(results[0])
    } catch {
      !this.isMapClicked && this.clearCoordinates()
      return
    }

    !this.isMapClicked && this.setFormValue && (
      this.setFormValue('coordinates', {latitude: latLng.lat, longitude: latLng.lng})
    )
  };

  clearCoordinates = (): void => {
    this.setFormValue && this.setFormValue('coordinates', null)
  }

  handleMapClick = (event: google.maps.MouseEvent): void => {
    this.isMapClicked = true
    const geocoder = new google.maps.Geocoder()

    this.setFormValue && this.setFormValue(
      'coordinates', 
      {
        latitude: event.latLng.lat(), 
        longitude: event.latLng.lng()
      }
    )

    geocoder.geocode({
      location: event.latLng
    }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          this.setFormValue && this.setFormValue('address', results[1]['formatted_address'])
        } else {
          this.setFormValue && this.setFormValue('address', '')
        }
      } else {
        this.setFormValue && this.setFormValue('address', '')
      }
    })
  }

  validate = (values: PlatformSaveRequest): ValidationErrors => {
    const errors: ValidationErrors = {}
    const platformSocialNotFilled =
      !!!values.facebookUrl?.length
        && !!!values.instagramUrl?.length
          && !!!values.vkontakteUrl?.length
            && !!!values.telegramUrl?.length ? 'Одно из этих полей должно быть заполнено' : undefined
    const contactSocialNotFilled =
      !!!values.contactFacebookUrl?.length
        && !!!values.contactInstagramUrl?.length
          && !!!values.contactVkontakteUrl?.length
            && !!!values.contactTwitterUrl?.length
              && !!!values.contactTelegramUrl?.length ? 'Одно из этих полей должно быть заполнено' : undefined

    errors.facebookUrl = platformSocialNotFilled
    errors.instagramUrl = platformSocialNotFilled
    errors.vkontakteUrl = platformSocialNotFilled
    errors.telegramUrl = platformSocialNotFilled

    errors.contactFacebookUrl = contactSocialNotFilled
    errors.contactInstagramUrl = contactSocialNotFilled
    errors.contactVkontakteUrl = contactSocialNotFilled
    errors.contactTwitterUrl = contactSocialNotFilled
    errors.contactTelegramUrl = contactSocialNotFilled

    if (values.equipmentCapabilities && !values.equipmentCapabilities.length && !values.noEquipment) {
      errors.equipmentCapabilities = 'Необходимо загрузить хотя бы 1 документ'
    }

    if (
      values.additionalEquipmentRequirements 
        && !values.additionalEquipmentRequirements.length 
          && !values.noAdditionalEquipment
    ) {
      errors.additionalEquipmentRequirements = 'Необходимо загрузить хотя бы 1 документ'
    }
    
    if (!values.coordinates) {
      errors.address = 'Введите корректный адрес или выберите метку на карте'
    }

    if (values.sceneGenres && !values.sceneGenres.length) {
      errors.sceneGenres = 'Необходимо выбрать как минимум 1 жанр'
    }

    return errors
  }

  render(): JSX.Element {
    const {
      isLoading,
      formInitialValues,
      comments,
      onSubmit,
      formStatus,
      submittionErrors,
      isSuccessModalVisible,
      isCheckModalVisible,
      onPopupApply,
      onCheckModalClose,
      onSuccessModalClose,
      isApplyLoading,
      isApproveLoading,
      isCheckLoading,
      onDeleteModalApply,
      onDeleteModalToggle,
      isDeleteModalVisible,
      isDeleteLoading
    } = this.props
    const userRole = localStorage.getItem('userRole')
    
    return (
      <div 
        className={classNames(
          block,
          this.state.isHintsVisible && `${block}--active-hints`
        )}
      >
        {isLoading && !!!formInitialValues ? (
          <LoaderComponent width={100} height={100} />
        ) : (
          <>
          <ModalComponent
            isVisible={isCheckModalVisible}
            title="Внимание!"
            onModalClose={onCheckModalClose}
          >
            <div className="margin--top-s">
              <div className="page-text page-text--align-center">
                Вычитанные анкеты автоматичеки публикуются на сайте Ural Music Night. <br/>
                Вы подтверждаете, что анкета полностью корректна и готова к публикации?
              </div>
              <div className="row row--space-evenly margin--top-l margin--bottom-xxs">
                <Button
                  text="Да"
                  background="purple"
                  onClick={(): void => this.formValues && onPopupApply(this.formValues)}
                  isLoading={isCheckLoading}
                />
                <Button
                  text="Нет"
                  background="pale-blue"
                  onClick={onCheckModalClose}
                />
              </div>
            </div>
          </ModalComponent>

          <ModalComponent
            isVisible={isSuccessModalVisible}
            title="Площадка была успешно опубликована!"
            onModalClose={onSuccessModalClose}
          >
            <div className="margin--auto margin--top-s margin--bottom-0">
              <Button
                text="OK"
                background="purple"
                onClick={onSuccessModalClose}
              />
            </div>
          </ModalComponent>

          <ModalComponent 
              isVisible={isDeleteModalVisible} 
              title="Удалить площадку и все её сцены" 
              onModalClose={onDeleteModalToggle}
            >
              <div className="margin margin--top-s margin--auto margin--bottom-0">
                Вы уверены, что хотите безвозвратно удалить площадку и все её сцены?
              </div>
              <div className="margin--top-l row row--space-evenly margin--bottom-xxs">
                <Button
                  onClick={onDeleteModalApply}
                  text="Удалить"
                  background="purple"
                  isLoading={isDeleteLoading}
                />
                <Button
                  onClick={onDeleteModalToggle}
                  text="Отмена"
                  background="pale-blue"
                isDisabled={isDeleteLoading}
                />
              </div>
            </ModalComponent>

          <Form
            onSubmit={(values): void => onSubmit(values, true)}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }): void => {
                changeValue(state, field, () => value)
              }
            }}
            initialValues={formInitialValues}
            validate={this.validate}
            render={({
              form: {
                mutators: { 
                  push,
                  setValue,
                  remove
                }
              },
              values,
              touched,
              handleSubmit,
              errors,
              active,
              hasValidationErrors
            }): JSX.Element => {
              this.formValues = values
              this.setFormValue = setValue
              const isSocialTouched = touched && (
                touched['facebookUrl'] || touched['instagramUrl'] || touched['vkontakteUrl'] || touched['telegramUrl']
              )

              const isContactsSocialTouched = touched && (
                touched['contactFacebookUrl']
                  || touched['contactInstagramUrl']
                    || touched['contactVkontakteUrl']
                      || touched['contactTwitterUrl']
                        || touched['contactTelegramUrl']
              )

              return (

              <div className="container row row--space-between">
                <form 
                  className={`${block}__form form`}
                  onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {            
                    event.preventDefault()
                    event.stopPropagation()
                    onSubmit(values, false)
                  }}
                  id="platform-form"
                >
                  <div>
                    <div className="form__title">
                      1. ОСНОВНАЯ ИНФОРМАЦИЯ
                    </div>
                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          * Название площадки
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="name" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    <span>Площадка</span> 
                                    - локация в физическом смысле: заведение / адрес / достопримечательность.  
                                    Именно название площадки указывается в этом разделе (пишется без кавычек).<br/><br/>

                                    <span>Сцена</span> - пространство, где будут выступать артисты. 
                                    В рамках одной площадки может быть несколько сцен разной направленности,
                                    тематики и жанра. 
                                    Каждая из них заполняется на отельной вкладке «Сцены» текущей анкеты. 
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-block margin margin--top-s">
                        <div className="form__input-title">
                          * Адрес
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="address" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                
                                <PlacesAutocomplete
                                  value={input.value}
                                  onChange={input.onChange}
                                  onSelect={(value: string): Promise<void> => {
                                    input.onChange(value)
                                    return this.handleAddressSelect(value)}
                                  }
                                >
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }): JSX.Element => (
                                    <div className={`${block}__address-input`}>
                                      
                                      <InputComponent
                                        {...getInputProps()}
                                        name={input.name}
                                        placeholder="Введите адрес"
                                        error={meta.touched && meta.error}
                                        onFocus={(): boolean => this.isMapClicked = false}
                                        onBlur={(event): void => {
                                          setTimeout(() => this.handleAddressSelect(input.value), 0)
                                          getInputProps().onBlur(event)
                                        }}
                                        onChange={(event): void => {
                                          input.onChange(event)
                                          getInputProps().onChange(event)
                                        }}
                                      />
                          
                                      <div className={`${block}__suggestions`}>
                                        {loading ? (
                                          <div className={`${block}__suggestions-loading`}>...loading</div>
                                        ) : (
                                          null
                                        )}
                          
                                        {suggestions.map((suggestion, index) => {
                                          const style = {
                                            backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                                          }
                          
                                          return (
                                            <div 
                                              {...getSuggestionItemProps(suggestion, { style })} 
                                              key={`address-suggestion-${index}`}
                                              className={`${block}__suggestions-item`}
                                            >
                                              {suggestion.description}
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  )}
                                  
                                </PlacesAutocomplete>


                                <GoogleMap
                                  zoom={12}
                                  center={
                                    values && values.coordinates ? ({
                                      lat: values.coordinates.latitude, 
                                      lng: values.coordinates.longitude
                                    }) : { lat: 56.83892609999999, lng: 60.6057025 }
                                  }
                                  onClick={this.handleMapClick}
                                  mapContainerClassName={`${block}__map margin--top-s`}
                                >
                                  {values && values.coordinates && (
                                    <Marker
                                      position={{ lat: values.coordinates.latitude, lng: values.coordinates.longitude }}
                                    />
                                  )}
                                </GoogleMap>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-row margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Сайт
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="site" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Телефон
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field
                              name="phone"
                              validate={fieldRequired}
                              parse={(value): string => {
                                if (value === '') {
                                  return value
                                } else {
                                  const newValue = value.replace(/\D/g, '')
                                  return `+${newValue}`
                                }
                              }}
                            >
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="+7 999 000 00 00"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Социальные сети площадки
                        </div>
                        <div className="form__input-row">
                          {platformSocial.map((inputData, index): JSX.Element => (
                            <div className="form__input margin margin--top-s" key={inputData.name}>
                              <Field
                                name={inputData.name}
                              
                              >
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder={inputData.placeholder}
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      icon={inputData.icon}
                                      error={isSocialTouched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    /> 
                                    {index === 1 && (
                                      <div className="form__input-label">
                                        <InputLabelComponent
                                          isFocused={
                                            active ? (
                                              active === 'facebookUrl'
                                              || active === 'instagramUrl'
                                              || active === 'vkontakteUrl'
                                              || active === 'telegramUrl'
                                            ) : false
                                          }
                                        >
                                          Обязательно нужно указать как минимум одну социальную сеть
                                        </InputLabelComponent>
                                      </div>
                                    )}              
                                    
                                  </Element>
                                )}
                              </Field>
                            </div>
                          ))}
                        </div>
                      </div>
                      
                      <div className="margin margin--top-s">
                        <Field
                          name="shareSocial"
                          type="checkbox"
                        >
                          {({ input }): JSX.Element => (
                            <CheckboxComponent
                              name="ready-to-share"
                              defaultIsChecked={formInitialValues && formInitialValues.shareSocial}
                              onChange={input.onChange}
                            >
                              Я готов предоставить социальные сети для анонсов;
                            </CheckboxComponent>
                          )}
                        </Field>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Описание площадки
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field
                            name="description"
                            validate={composeValidators(
                              fieldRequired,
                              value => value.length > 250 ? (
                                'Описание должно быть уложено в 250 символов.'
                              ) : undefined
                            )}
                          >
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <TextAreaComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Описание должно быть уложено в 250 символов.
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      2. КОНТАКТНОЕ ЛИЦО
                    </div>

                    <div className="form__form-block">

                      <div className="form__input-row">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * ФИО
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="contactName" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Телефон
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field
                              name="contactPhone"
                              validate={fieldRequired}
                              parse={(value): string => {
                                if (value === '') {
                                  return value
                                } else {
                                  const newValue = value.replace(/\D/g, '')
                                  return `+${newValue}`
                                }
                              }}
                            >
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="+7 999 000 00 00"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-row margin margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Email
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="contactEmail" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        {contactSocial.map((inputData, index): JSX.Element => (
                          <div className="form__input margin margin--top-s" key={inputData.name}>
                            <Field
                              name={inputData.name}
                            
                            >
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder={inputData.placeholder}
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    icon={inputData.icon}
                                    error={isContactsSocialTouched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  /> 
                                  {index === 0 && (
                                    <div className="form__input-label">
                                      <InputLabelComponent
                                        isFocused={
                                          active ? (
                                            active === 'contactFacebookUrl'
                                            || active === 'contactInstagramUrl'
                                            || active === 'contactVkontakteUrl'
                                            || active === 'contactTelegramUrl'
                                            || active === 'contactTwitterUrl'
                                          ) : false
                                        }
                                      >
                                        Обязательно нужно указать как минимум одну социальную сеть
                                      </InputLabelComponent>
                                    </div>
                                  )}              
                                  
                                </Element>
                              )}
                            </Field>
                          </div>
                        ))}
                      </div>

                    </div>  
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      3. МЕДИАМАТЕРИАЛЫ
                    </div>

                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className={classNames(
                          "form__input-title",
                          (this.props.showValidationErrors && (
                            errors.photoSite || errors.photoSite2 || errors.photoSiteHeader
                          )) && "form__input-title--error"
                        )}>
                          * Фотографии
                        </div>
                        <div className={classNames(
                          `form__images-row`,
                          `${block}__images-row`
                        )}>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoSite" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore={input.name}
                                    title="На сайт"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoSite ? values.photoSite : undefined}
                                    onFileDelete={(): void => {
                                      input.onChange(null)
                                    }}
                                    showFileName={false}
                                    minWidth={600}
                                    minHeight={400}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoSite2" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore={input.name}
                                    title="&nbsp;"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoSite2 ? values.photoSite2 : undefined}
                                    onFileDelete={(): void => {
                                      input.onChange(null)
                                    }}
                                    showFileName={false}
                                    minWidth={600}
                                    minHeight={400}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                          <div className="form__input margin margin--top-xs">
                            <Field name="photoSiteHeader" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <UploadFileContainer
                                    nameForStore={input.name}
                                    title="В шапку сайта"
                                    fileTypes={["image/jpeg", "image/png"]}
                                    onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                    uploadedFile={values.photoSiteHeader ? values.photoSiteHeader : undefined}
                                    onFileDelete={(): void => {
                                      input.onChange(null)
                                    }}
                                    showFileName={false}
                                    minWidth={2880}
                                    minHeight={1100}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>

                          <div className={classNames(
                            "form__input-label",
                            `${block}__photo-input-label`
                          )}>
                            <InputLabelComponent>
                              На сайт: горизонтальная фотография - <span>600 х 400 px;</span><br/>
                              В шапку сайта: горизонтальная - <span>2880 х 1100 px;</span>
                            </InputLabelComponent>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin margin--top-s">
                        <div className={classNames(
                          "form__input-title",
                          this.props.showValidationErrors && errors.logo && (
                            "form__input-title--error"
                          )
                        )}>
                          * Логотип
                        </div>
                        <div className="form__input margin margin--top-xs">
                          <Field name="logo" validate={fieldRequired}>
                            {({ input }): JSX.Element => (
                              <Element name={input.name}>
                                <UploadFileContainer
                                  nameForStore={input.name}
                                  fileTypes={[".cdr", ".ai", ".png"]}
                                  onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                  uploadedFile={values.logo ? values.logo : undefined}
                                  onFileDelete={(): void => {
                                    input.onChange(null)
                                  }}
                                  showFileName={false}
                                  minHeight={1}
                                  minWidth={1}
                                />
                              </Element>
                            )}
                          </Field>
                          <div className={classNames(
                            "form__input-label", 
                            `${block}__photo-input-label`
                          )}>
                            <InputLabelComponent>
                              Допустимые форматы: cdr, ai, png.
                            </InputLabelComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      4. СЦЕНА
                    </div>

                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          * Творческое название сцены
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="sceneName" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="form__input-block margin margin--top-s">
                        <div className={classNames(
                          "form__input-title",
                          this.props.showValidationErrors && errors.sceneGenres && "form__input-title--error"
                        )}>
                          * Музыкальный жанр
                        </div>
                        <div className="form__input">
                          <FieldArray name="genres">
                            {({ fields }): JSX.Element[] => (
                              fields.map((name): JSX.Element => (
                                <div key={`genres[${name}]`} />
                              ))
                            )}
                          </FieldArray>

                          
                          <Element name="sceneGenres">
                            <div className="row row--wrap">
                              {sceneGenreButtonsData.map((
                                buttonData: SceneGenreButtonsData
                              ): JSX.Element => (
                                <Field
                                  name={buttonData.value}
                                  type="checkbox"
                                  key={buttonData.value}
                                  value={buttonData.value}
                                >
                                  {({ input }): JSX.Element => (
                                    <div className={'margin margin--top-xxs'}>
                                      <GenreRadioButtonComponent
                                        checked={
                                          values.sceneGenres 
                                            && values.sceneGenres.length > 0 
                                              && values.sceneGenres.includes(buttonData.value)
                                        }
                                        caption={buttonData.caption}
                                        name={input.name}
                                        value={input.value}
                                        onChange={values.sceneGenres && values.sceneGenres.length < 2
                                          && !values.sceneGenres.includes(buttonData.value) ? (
                                          (): void => push('sceneGenres', buttonData.value)
                                        ) : (
                                          (): void => (
                                            values.sceneGenres.includes(buttonData.value) && (
                                              remove('sceneGenres', values.sceneGenres.indexOf(buttonData.value))
                                            )
                                          )
                                        )}
                                        icon={buttonData.icon}
                                        type="checkbox"
                                      />
                                      <div className="form__input-label">
                                        <InputLabelComponent>
                                          Можно выбрать максимум 2 жанра
                                        </InputLabelComponent>
                                      </div>
                                    </div>
                                  )}
                                </Field>
                              ))}
                            </div>
                          </Element>
                        </div>
                      </div>
                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Музыкальное направление сцены
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="sceneSubgenre" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Например: &quot;Поп-панк&quot; или &quot;электроника-хаус&quot;
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-row margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Дата начала работы
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="sceneDate" validate={fieldRequired}>
                              {({ input }): JSX.Element => (
                                <Element name={input.name}>
                                  <SelectComponent
                                    value={input.value}
                                    onChange={input.onChange}
                                    placeholder="Выберите дату"
                                    options={perfomanceDates}
                                    name={input.name}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-row margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Время начала работы
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="sceneStartTime" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <TimePicker
                                    onChange={(momentValue): void => (
                                      !!momentValue ? (
                                        input.onChange(`${momentValue.format('HH:mm')}:00`)
                                      ) : (
                                        input.onChange(undefined)
                                      )
                                    )}
                                    className={classNames(
                                      'form-time-picker',
                                      meta.touched && meta.error && 'form-time-picker--error'
                                    )}
                                    popupClassName="form-time-picker-popup"
                                    showSecond={false}
                                    minuteStep={30}
                                    value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                    onOpen={(): void => input.onFocus()}
                                    onClose={(): void => input.onBlur()}
                                    placeholder="Выберите время"
                                    focusOnOpen={false}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
                                  />
                                  {meta.touched && meta.error && (
                                    <div className="form-time-picker__error">
                                      {meta.error}
                                    </div>
                                  )}
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Время конца работы
                          </div>
                          <div className="form__input margin margin--top-xxs">
                            <Field name="sceneEndTime" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <TimePicker
                                    onChange={(momentValue): void => (
                                      !!momentValue ? (
                                        input.onChange(`${momentValue.format('HH:mm')}:00`)
                                      ) : (
                                        input.onChange(undefined)
                                      )
                                    )}
                                    className={classNames(
                                      'form-time-picker',
                                      meta.touched && meta.error && 'form-time-picker--error'
                                    )}
                                    popupClassName="form-time-picker-popup"
                                    showSecond={false}
                                    minuteStep={30}
                                    value={input.value ? moment(input.value, 'HH:mm') : undefined}
                                    onOpen={(): void => input.onFocus()}
                                    onClose={(): void => input.onBlur()}
                                    placeholder="Выберите время"
                                    focusOnOpen={false}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
                                  />
                                  {meta.touched && meta.error && (
                                    <div className="form-time-picker__error">
                                      {meta.error}
                                    </div>
                                  )}
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Концепция площадки
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field
                            name="sceneDescription"
                            validate={composeValidators(
                              fieldRequired,
                              value => value.length < 300 || value.length > 1000 ? (
                                'Описание должно быть от 300 до 1000 символов'
                              ) : undefined
                            )}
                          >
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <TextAreaComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Опишите для СМИ, какая идея лежит в основе мероприятия, 
                                    что объединяет всех артистов<br/> (От 300 до 1000 знаков).
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      5. ДОПОЛНИТЕЛЬНЫЕ МЕДИАМЕТЕРИАЛЫ     
                    </div>

                    <div className="form__form-block">
                      <div className="form__input-title">
                        Количество:
                      </div>
                      <div className="form__input-row margin--top-xs">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            Афиш А4
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.postersA4" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block">
                          <div className="form__input-title">
                            Афиш А3
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.postersA3" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                            <div className={classNames(
                              "form__input-label"
                            )}>
                              <InputLabelComponent
                                isFocused={
                                  active ? (
                                    active as string === 'numbers.postersA0'
                                    || active as string === 'numbers.postersA1'
                                    || active as string === 'numbers.postersA2'
                                    || active as string === 'numbers.postersA3'
                                    || active as string === 'numbers.postersA4'
                                    || active as string === 'numbers.banners'
                                    || active as string === 'numbers.flyersA6'
                                    || active as string === 'numbers.stretchingBanners'
                                    || active as string === 'numbers.tableTentsA5'
                                    || active as string === 'numbers.tableTentsA6'
                                  ) : false
                                }
                              >
                                Укажите количество и формат печатных материалов, 
                                которые хотите разместить на площадке, 
                                чтобы мы подготовили необходимые макеты;
                              </InputLabelComponent>
                            </div>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Афиш А2
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.postersA2" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                        
                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Афиш А1
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.postersA1" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <Element name="numbers"></Element>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Афиш А0
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.postersA0" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                        
                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Баннеров
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.banners" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>

                            <div className={classNames(
                              "form__input-label"
                            )}>
                              <InputLabelComponent
                                isFocused={
                                  active ? (
                                    active as string === 'numbers.banners'
                                  ) : false
                                }
                              >
                                Укажите размер и расположение люверсов
                              </InputLabelComponent>
                            </div>
                          </div>
                        </div>
                        
                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Флаеров А6
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.flyersA6" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Растяжек
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.stretchingBanners" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Тейбл-тентов А5
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.tableTentsA5" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block margin--top-s">
                          <div className="form__input-title">
                            Тейбл-тентов А6
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="numbers.tableTentsA6" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                    onFocus={input.onFocus}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Дополнительные возможности размещения рекламы о площадке:
                        </div>
                        <div className="form__input margin--top-xxs">
                          <Field name="additionalAdvertisingCapabilities" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className={classNames(
                                  "form__input-label"
                                )}>
                                  <InputLabelComponent
                                    isFocused={meta.active}
                                  >
                                    Размещение афиш, видео, баннеров и другого вне площадки. 
                                    Нужно указать формат макетов или другие необходимые технические требования.
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div> 
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          Логотипы партнеров площадки
                        </div>

                        <div className={classNames(
                          `form__images-row`,
                          `${block}__images-row`
                        )}>
                          <FieldArray name="partnerLogos" >
                            {({ fields }): JSX.Element[] => (
                              fields.map((name, index): JSX.Element => (
                                <div className={classNames(
                                  'form__input',
                                  index < 4 ? 'margin--top-xs' : 'margin--top-xxs'
                                )} key={`partner-logo-${index}`}>
                                  <Field name={`partnerLogos[${index}]`}>
                                    {({ input }): JSX.Element => (
                                      <Element name={input.name}>
                                        <UploadFileContainer
                                          nameForStore={`partner-logo-${index}`}
                                          fileTypes={[".cdr", ".ai", ".png"]}
                                          onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                          uploadedFile={values.partnerLogos[index]}
                                          onFileDelete={(): void => {
                                            input.onChange(null)
                                          }}
                                          showFileName={false}
                                          minWidth={20}
                                          minHeight={20}
                                        />
                                      </Element>
                                    )}
                                  </Field>
                                </div>
                              ))
                            )}
                          </FieldArray>
                          <div className={classNames(
                            "form__input-label margin--top-xs"
                          )}>
                            <InputLabelComponent>
                              Допустимые форматы: cdr, ai, png.
                            </InputLabelComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      6. Техническая информация
                    </div>

                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          * Технические требования мультимедийных экранов
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="multimediaScreenSpecification" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Укажите тех. требования к видео, которое мы сможем транслировать 
                                    на ваших экранах внутри помещения или на фасаде (если они у вас имеются)
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="form__input-block margin--top-s">
                        <div className={classNames(
                          "form__input-title",
                          this.props.showValidationErrors && errors.equipmentCapabilities && "form__input-title--error"
                        )}>
                          {!values.noEquipment && '*'} Возможности оборудования на площадке
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Element name="equipmentCapabilities">
                            <FieldArray name="equipmentCapabilities">
                              {({ fields }): JSX.Element[] => (
                                fields.map((name, index): JSX.Element => (
                                  <div className="form__document" key={`document-${index}`}>
                                    <div className="form__document-img">
                                      <Doc fill="#000" />
                                    </div>
                                    <a
                                      href={
                                        values.equipmentCapabilities ? values.equipmentCapabilities[index]?.url : ''
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="form__document-link"
                                    >
                                      {values.equipmentCapabilities && values.equipmentCapabilities[index]?.name}
                                    </a>
                                    <div 
                                      className="form__document-remove"
                                      onClick={(): string => fields.remove(index)}
                                    >
                                      <Remove width="15" height="15" />
                                    </div>
                                  </div>
                                )
                              ))}
                            </FieldArray>
                          </Element>

                          <div className="margin margin--top-xs">
                            <DocumentUploadContainer 
                              nameForStore={'equipment-apabilities-upload'} 
                              fileTypes={[".doc", ".pdf", ".docx"]}
                              onFileUpload={(uploadedFile): void => push('equipmentCapabilities', uploadedFile)}
                            />
                          </div>
                          
                          <div className="form__input-label">
                            <InputLabelComponent>
                              Фронт/ беклайн/ DJ- оборудование / музыкальные инструменты / мультимедиа–экраны.<br/>
                              Допустимые форматы: doc, pdf
                            </InputLabelComponent>
                          </div>
                        </div>
                      </div>
                      
                      <div className="margin--top-s">
                        <Field
                          name="noEquipment"
                          type="checkbox"
                        >
                          {({ input }): JSX.Element => (
                            <CheckboxComponent
                              name={input.name}
                              defaultIsChecked={formInitialValues && formInitialValues.noEquipment}
                              onChange={input.onChange}
                            >
                              На площадке нет своего оборудования
                            </CheckboxComponent>
                          )}
                        </Field>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className={classNames(
                          "form__input-title",
                          this.props.showValidationErrors 
                            && errors.additionalEquipmentRequirements 
                              && "form__input-title--error"
                        )}>
                          {!values.noAdditionalEquipment && '*'} Необходимое дополнительное оборудование
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Element name="additionalEquipmentRequirements">
                            <FieldArray name="additionalEquipmentRequirements">
                              {({ fields }): JSX.Element[] => (
                                fields.map((name, index): JSX.Element => (
                                  <div className="form__document" key={`document-${index}`}>
                                    <div className="form__document-img">
                                      <Doc fill="#000" />
                                    </div>
                                    <a
                                      href={
                                        values.additionalEquipmentRequirements ? (
                                          values.additionalEquipmentRequirements[index]?.url
                                        ) : ''
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="form__document-link"
                                    >
                                      {values.additionalEquipmentRequirements && 
                                        values.additionalEquipmentRequirements[index]?.name}
                                    </a>
                                    <div 
                                      className="form__document-remove"
                                      onClick={(): string => fields.remove(index)}
                                    >
                                      <Remove width="15" height="15" />
                                    </div>
                                  </div>
                                )
                              ))}
                            </FieldArray>
                          </Element>

                          <div className="margin margin--top-xs">
                            <DocumentUploadContainer 
                              nameForStore={'additional-equipment-requirements-upload'} 
                              fileTypes={[".doc", ".pdf", ".docx"]}
                              onFileUpload={(uploadedFile): void => (
                                push('additionalEquipmentRequirements', uploadedFile)
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="margin--top-s">
                        <Field
                          name="noAdditionalEquipment"
                          type="checkbox"
                        >
                          {({ input }): JSX.Element => (
                            <CheckboxComponent
                              name={input.name}
                              defaultIsChecked={formInitialValues && formInitialValues.noAdditionalEquipment}
                              onChange={input.onChange}
                            >
                              Нам не нужно дополнительное оборудование
                            </CheckboxComponent>
                          )}
                        </Field>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          * Охрана
                        </div>
                        <div className="form__input margin margin--top-xxs">
                          <Field name="security" validate={fieldRequired}>
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Укажите есть ли у вас охрана и сколько человек.
                                    Будет ли она на мероприятии? Если нет - то нужно ли и сколько?
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-row margin--top-s">
                        <div className="form__input-block">
                          <div className="form__input-title">
                            * Вместимость (чел.)
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="capacity" validate={fieldRequired}>
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form__input-block">
                          <div className="form__input-title">
                            Средний чек (руб.)
                          </div>
                          <div className="form__input margin--top-xxs">
                            <Field name="averageCheck">
                              {({ input, meta }): JSX.Element => (
                                <Element name={input.name}>
                                  <InputComponent
                                    placeholder="Введите текст"
                                    value={input.value}
                                    name={input.name}
                                    onChange={input.onChange}
                                    error={meta.touched && meta.error}
                                    onBlur={input.onBlur}
                                  />
                                </Element>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          Волонтеры
                        </div>
                        <FieldArray name="volunteers">
                          {({ fields }): JSX.Element[] => (
                            fields.map((name, index): JSX.Element => (
                              <div key={`volunteers-input-${name}`} className="form__input margin--top-xxs">
                                <Field name={`volunteers[${index}]`}>
                                  {({ input }): JSX.Element => (
                                    <Element name={input.name}>
                                      <InputComponent
                                        placeholder="Функция"
                                        value={input.value}
                                        name={input.name}
                                        onChange={input.onChange}
                                        onFocus={input.onFocus}
                                        onBlur={input.onBlur}
                                      />
                                      {index === 0 && (
                                        <div className="form__input-label">
                                          <InputLabelComponent
                                            isFocused={
                                              active ? (
                                                active.indexOf('volunteers') >= 0 
                                              ) : false
                                            }
                                          >
                                            Мы готовы предоставить волонтеров. 
                                            Если вам они нужны - напишите функции для каждого.
                                          </InputLabelComponent>
                                        </div>
                                      )}
                                    </Element>
                                  )}
                                </Field>
                              </div>
                            ))
                          )}
                        </FieldArray>
                      </div>

                      <div className="margin margin--top-s">
                        <AddMemberButtonComponent
                          text="Добавить функцию"
                          onClick={(): void => push('volunteers', undefined)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="margin margin--top-s">
                    <div className="form__title">
                      7. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ
                    </div>

                    <div className="form__form-block">
                      <div className="form__input-block">
                        <div className="form__input-title">
                          Возможность партнерской email рассылки о фестивале
                        </div>
                        <div className="form__input margin--top-xxs">
                          <Field name="partnerEmailNewsletter">
                            {({ input, meta }): JSX.Element => (
                              <Element name={input.name}>
                                <InputComponent
                                  placeholder="Введите текст"
                                  value={input.value}
                                  name={input.name}
                                  onChange={input.onChange}
                                  error={meta.touched && meta.error}
                                  onBlur={input.onBlur}
                                  onFocus={input.onFocus}
                                />
                                <div className="form__input-label">
                                  <InputLabelComponent isFocused={meta.active}>
                                    Укажите контактное лицо ответственного и 
                                    тех. требования (по желанию).
                                  </InputLabelComponent>
                                </div>
                              </Element>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="form__input-block margin--top-s">
                        <div className="form__input-title">
                          Участие в розыгрышах
                        </div>
                        <div className="form__input margin--top-xxs">
                          <Field name="lottery">
                            {({ input, meta }): JSX.Element => (
                              <>
                              <TextAreaComponent
                                placeholder="Введите текст"
                                value={input.value}
                                name={input.name}
                                onChange={input.onChange}
                                error={meta.touched && meta.error}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                              />
                              <div className="form__input-label">
                                <InputLabelComponent isFocused={meta.active}>
                                  Если есть желание разыграть что-то среди аудитории фестиваля, 
                                  то напишите об этом и укажите контактное лицо, для координации.
                                </InputLabelComponent>
                              </div>
                              </>
                            )}
                          </Field>
                        </div>
                      </div>

                    </div>
                    
                    {(formStatus === 'EDITING' || formStatus === 'EMPTY') && userRole === 'ADMIN' && (
                      <>
                      <div className="margin margin--top-m">
                        <Button
                          text="Одобрить площадку"
                          background="purple"
                          onClick={(): void => {
                            this.scrollToFirstError(errors)
                            this.props.showErrors()
                            handleSubmit()
                          }}
                          isLoading={isApproveLoading}
                        />
                      </div>

                      {submittionErrors && submittionErrors.length && (
                        submittionErrors.map((error, index: number) => (
                          <ErrorLabelComponent text={error.text} key={`${error.code}-${index}`} />
                        ))
                      )}
                      </>
                    )}

                    {userRole === 'EXPERT' && (
                      <>
                      <div className="margin margin--top-m">
                        <Button
                          text={'Отправить на модерацию'}
                          background="purple"
                          onClick={(): void => {
                            this.scrollToFirstError(errors)
                            this.props.showErrors()
                            handleSubmit()
                          }}
                          isLoading={isApplyLoading}
                        />
                      </div>
                      
                      {submittionErrors && submittionErrors.length && (
                        submittionErrors.map((error, index: number) => (
                          <ErrorLabelComponent text={error.text} key={`${error.code}-${index}`} />
                        ))
                      )}
                      </>
                    )}

                  </div>
                </form>

                <div className="margin margin--top-xl">
                  <ModerationSidebarContainer
                    onModerate={this.onModerate}
                    onDelete={onDeleteModalToggle}
                    onApprove={(): void => {
                      this.formValues && this.props.onApprove(this.formValues)
                    }}
                    isApproveActive={!hasValidationErrors}
                    comments={comments}
                    onCheckForm={this.props.onCheckForm}
                    formStatus={formStatus}
                    submittionErrors={submittionErrors}
                    onTabChange={(index): void => {
                      this.setState({
                        isHintsVisible: sidebarTabs['hints'].index === index,
                        sidebarActiveTabIndex: index
                      })
                    }}
                    activeTabIndex={this.state.sidebarActiveTabIndex}
                  />
                </div>
              </div>
              )}}
          />
          </>
        )}
      </div>
    )
  }
}