import React from 'react'
import AdminApproveSidebarComponent from '../../../../../components/CRM/AdminCRM/AdminApproveSidebar'
import { PlatformStatus } from '../../../../../models/ScenesModels/platformList.model'
import { CommentModel } from '../../../../../models/ArtistFormModels/artistForm.model'
import { formCommentStore } from '../../../../../stores/CRM/AdminCRM/artists/formComment.store'
import { observer } from 'mobx-react'
import { RequestError } from '../../../../../models/common/error.model'
import { 
  platformFormModerateStore, 
  platformFormApproveStore, 
  platformFormReadStore, 
  platformFormGetStore
} from '../../../../../stores/CRM/AdminCRM/scenes/platformGetStore'

interface PropTypes {
  onModerate: () => void;
  onApprove: () => void;
  onCheckForm: () => void;
  isApproveActive: boolean;
  comments?: CommentModel[];
  formStatus: PlatformStatus;
  submittionErrors?: RequestError[];
  onTabChange: (tabIndex: number) => void;
  activeTabIndex: number;
  onDelete: () => void;
}

@observer
export default class ModerationSidebarContainer extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { 
      onModerate, 
      onApprove, 
      onCheckForm,
      isApproveActive,
      comments,
      formStatus,
      submittionErrors,
      onTabChange,
      activeTabIndex,
      onDelete
    } = this.props
    console.log('isApprove', isApproveActive)
    

    return (
      <AdminApproveSidebarComponent
        onModerate={onModerate}
        onApprove={onApprove}
        isModerateActive={!!formCommentStore.comment.length}
        isApproveActive={isApproveActive}
        comments={comments}
        onCheckForm={onCheckForm}
        formStatus={formStatus}
        submittionErrors={submittionErrors}
        commentInputTitle={'КОММЕНТАРИЙ'}
        isModerateLoading={platformFormModerateStore.isLoading}
        isApproveLoading={platformFormApproveStore.isLoading}
        isCheckLoading={platformFormReadStore.isLoading}
        onTabChange={onTabChange}
        activeTabIndex={activeTabIndex}
        onDelete={onDelete}
        isCheckedStatus={!!platformFormGetStore.checkedAt}
      />
    )
  }
}